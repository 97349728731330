import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Styles from "./form.module.css";
import axios from "axios";

// Import dos Input
import Email from "../inputs_cadastro/email_input";
import Senha from "../inputs_cadastro/senha_input";
import Nome from "../inputs_cadastro/nome_input";
import CPF from "../inputs_cadastro/cpf_input";
import RG from "../inputs_cadastro/rg_input";
import Telefone from "../inputs_cadastro/telefone_input";
import DtNasc from "../inputs_cadastro/dt_nasc_input";
import DC from "../inputs_cadastro/destro_canhoto_input";
import Genero from "../inputs_cadastro/genero_input";
// Import dos Input de Endereço
import Cep from "../inputs_cadastro/endereco/cep_input";
import UF from "../inputs_cadastro/endereco/uf_input";
import Cidade from "../inputs_cadastro/endereco/cidade_input";
import Bairro from "../inputs_cadastro/endereco/bairro_input";
import Rua from "../inputs_cadastro/endereco/rua_input";

export default function Form() {

  const [step, setStep] = useState(0);
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [cpf, setCpf] = useState("");
  const [rg, setRg] = useState("");
  const [telefone, setTelefone] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [id_endereco, setEndereco] = useState(null);
  const [genero, setGenero] = useState("");
  const [mao_dominante, setMao_Dominante] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [nome, setNome] = useState("");
  const [cep, setCep] = useState("");
  const [responsePessoa, setResponsePessoa] = useState(null);
  const [emailResp, setEmailResp] = useState("");
  const [senhaResp, setSenhaResp] = useState("");
  const [nomeResp, setNomeResp] = useState("");
  const [nascimentoResp, setNascimentoResp] = useState("");
  const [generoResp, setGeneroResp] = useState("");
  const [cpfResp, setCpfResp] = useState("");
  const [rgResp, setRgResp] = useState("");
  const [telefoneResp, setTelefoneResp] = useState("");

  const [idade, setIdade] = useState("");

  let { id_aluno } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (id_aluno !== undefined) {
      logado();
      id_aluno = parseInt(id_aluno);
      preencherDados();
    }
  }, [id_aluno]);

  const calcularIdade = (dataNascimento) => {
    const partes = dataNascimento.split('/');

    const hoje = new Date();
    const nascimento = new Date(`${partes[2]}-${partes[1]}-${partes[0]}`);
    let idadeCalculada = hoje.getFullYear() - nascimento.getFullYear();
    const mes = hoje.getMonth();
    if (mes < nascimento.getMonth() || (mes === nascimento.getMonth() && hoje.getDate() < nascimento.getDate())) {
      idadeCalculada--;
    }
    setIdade(idadeCalculada);
  };
  const isUnder18 = () => {
    console.log("Idade do aluno:", idade);  // Adicione este log para depuração
    return idade < 18 && idade > 0;
  }

  const formatCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const formatRG = (rg) => {
    return rg.replace(/(\d{2})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  function padraoBR(isoDate) {
    const date = new Date(isoDate);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Meses começam em 0
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  const formatTelefone = (telefone) => {
    telefone = telefone.replace(/\D/g, ''); // Remove non-digits
    if (telefone.length === 11) {
      return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else if (telefone.length === 10) {
      return telefone.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    return telefone;
  };

  const formatDate = (nascimento) => {
    nascimento = nascimento.replace(/\D/g, '');
    nascimento = nascimento.replace(/(\d{2})(\d{2})(\d)/, '$1/$2/$3');
    return nascimento;
  }

  const convertDate = (date) => {
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  };

  const handleBuscarCep = (cep) => {
    if (cep.length < 9) {
      setLogradouro("");
      setBairro("");
      setCidade("");
      setUf("");
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => response.json())
      .then((dados) => {
        setLogradouro(dados.logradouro);
        setBairro(dados.bairro);
        setCidade(dados.localidade);
        setUf(dados.uf);
      })
      .catch((error) => {
        console.error('Erro ao buscar CEP:', error);
      });
  };

  const nextStep = () => {
    setStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const steps = [
    <Passo1 nextStep={nextStep} rg={rg} setRg={setRg} cpf={cpf} setCpf={setCpf} email={email} setEmail={setEmail} senha={senha} setSenha={setSenha} nome={nome} setNome={setNome} />,
    <Passo2 nextStep={nextStep} prevStep={prevStep} telefone={telefone} setTelefone={setTelefone} nascimento={nascimento} setNascimento={setNascimento} calcularIdade={calcularIdade} genero={genero} setGenero={setGenero} mao_dominante={mao_dominante} setMao_Dominante={setMao_Dominante} />,
    <Passo3 nextStep={nextStep} prevStep={prevStep} handleBuscarCep={handleBuscarCep} cep={cep} setCep={setCep} uf={uf} cidade={cidade} bairro={bairro} logradouro={logradouro} isUnder18={isUnder18} cliquei={cliquei} />,
    <Passo4 nextStep={nextStep} prevStep={prevStep} rgResp={rgResp} setRgResp={setRgResp} cpfResp={cpfResp} setCpfResp={setCpfResp} emailResp={emailResp} setEmailResp={setEmailResp} nomeResp={nomeResp} setNomeResp={setNomeResp} senhaResp={senhaResp} setSenhaResp={setSenhaResp} />,
    <Passo5 prevStep={prevStep} telefoneResp={telefoneResp} setTelefoneResp={setTelefoneResp} nascimentoResp={nascimentoResp} setNascimentoResp={setNascimentoResp} generoResp={generoResp} setGeneroResp={setGeneroResp} cliquei={cliquei} />,
  ];

  const logado = async () => {
    try {
      let response = await axios.post('/login');
      if (response.data.adm !== 1) {
        navigate('/home');
      }
    } catch (error) {
      navigate('/');
    }
  };

  function tratamentoString(inputString) {
    return inputString.replace(/[.\-()\s]/g, '');
  }

  // Obter a data atual
  const dataAtual = new Date();
  const adicionarZero = (numero) => (numero < 10 ? `0${numero}` : numero);
  const ano = dataAtual.getFullYear();
  const mes = adicionarZero(dataAtual.getMonth() + 1);
  const dia = adicionarZero(dataAtual.getDate());
  const dataFormatadaMySQL = `${ano}-${mes}-${dia}`;

  const preencherDados = async () => {
    try {
      let responsePessoa = await axios.get('/api/pessoa');
      let responsePessoaR = responsePessoa.data;
      responsePessoa = responsePessoa.data;
      responsePessoa = responsePessoa.find(item => item.id_pessoa === id_aluno);
      setEndereco(responsePessoa.id_endereco);
      setEmail(responsePessoa.email_pessoa);
      setNome(responsePessoa.nome_pessoa);
      setCpf(formatCPF(responsePessoa.cpf_pessoa));
      setRg(formatRG(responsePessoa.rg_pessoa));
      setTelefone(formatTelefone(responsePessoa.telefone_pessoa));
      setNascimento(padraoBR(responsePessoa.dt_nasc_pessoa));
      let responseAluno = await axios.get('/api/aluno');
      responseAluno = responseAluno.data;
      responseAluno = responseAluno.find(item => item.id_pessoa === id_aluno);
      let id_responsavel = responseAluno.id_responsavel;
      setMao_Dominante(responseAluno.destro_canhoto);
      setGenero(responsePessoa.genero);
      let responseEndereco = await axios.get('/api/endereco');
      responseEndereco = responseEndereco.data;
      responseEndereco = responseEndereco.find(item => item.id_endereco === responsePessoa.id_endereco);
      setCep(responseEndereco.cep);
      setUf(responseEndereco.estado);
      setCidade(responseEndereco.cidade);
      setBairro(responseEndereco.bairro);
      setLogradouro(responseEndereco.rua);
      responsePessoaR = responsePessoaR.find(item => item.id_pessoa === id_responsavel);
      setEmailResp(responsePessoaR.email_pessoa);
      setNomeResp(responsePessoaR.nome_pessoa);
      setRgResp(formatRG(responsePessoaR.rg_pessoa));
      setCpfResp(formatCPF(responsePessoaR.cpf_pessoa));
      setTelefoneResp(formatTelefone(responsePessoaR.telefone_pessoa));
      setNascimentoResp(padraoBR(responsePessoaR.dt_nasc_pessoa));
      setGeneroResp(responsePessoaR.genero);
    } catch (error) {
      console.log(error);
    }
  };

  async function cliquei() {
    if (id_aluno !== undefined) {
      if (nomeResp === "") {
        try {
          let responseEndereco = await axios.put(`/api/endereco/${id_endereco}`, {
            cep: cep,
            estado: uf,
            cidade: cidade,
            bairro: bairro,
            rua: logradouro,
            numero: null
          });

          let responsePessoa = await axios.put(`/api/pessoa/${id_aluno}`, {
            nome_pessoa: nome,
            dt_nasc_pessoa: convertDate(nascimento),
            cpf_pessoa: tratamentoString(cpf),
            rg_pessoa: tratamentoString(rg),
            email_pessoa: email,
            telefone_pessoa: tratamentoString(telefone),
            genero: genero,
            id_endereco: id_endereco
          });

          const responseAluno = await axios.put(`/api/aluno/${id_aluno}`, {
            id_pessoa: id_aluno,
            destro_canhoto: mao_dominante,
            id_responsavel: null,
          });

          setResponsePessoa(responseAluno);
        } catch (error) {
          console.log("Erro ao criar aluno: ", error);
        }
      } else {
        try {
          id_aluno = parseInt(id_aluno)
          let dadosPessoaR = await axios.get('/api/aluno');
          dadosPessoaR = dadosPessoaR.data;
          dadosPessoaR = dadosPessoaR.find(item => item.id_pessoa === id_aluno);
          let id_responsavel = dadosPessoaR.id_responsavel;

          let responseEndereco = await axios.put(`/api/endereco/${id_endereco}`, {
            cep: cep,
            estado: uf,
            cidade: cidade,
            bairro: bairro,
            rua: logradouro,
            numero: null
          });

          let responsePessoa = await axios.put(`/api/pessoa/${id_aluno}`, {
            nome_pessoa: nome,
            dt_nasc_pessoa: convertDate(nascimento),
            cpf_pessoa: tratamentoString(cpf),
            rg_pessoa: tratamentoString(rg),
            email_pessoa: email,
            telefone_pessoa: tratamentoString(telefone),
            genero: genero,
            id_endereco: id_endereco
          });

          let responsePessoaR = await axios.put(`/api/pessoa/${id_responsavel}`, {
            nome_pessoa: nomeResp,
            dt_nasc_pessoa: convertDate(nascimentoResp),
            cpf_pessoa: tratamentoString(cpfResp),
            rg_pessoa: tratamentoString(rgResp),
            email_pessoa: emailResp,
            telefone_pessoa: tratamentoString(telefoneResp),
            genero: generoResp,
            id_endereco: id_endereco
          });

          const responseAluno = await axios.put(`/api/aluno/${id_aluno}`, {
            id_pessoa: id_aluno,
            destro_canhoto: mao_dominante,
            id_responsavel: id_responsavel,
          });

          setResponsePessoa(responseAluno);
        } catch (error) {
          console.log("Erro ao criar aluno: ", error);
        }
      }
    } else {
      if (nomeResp === "") {
        try {
          let responseEndereco = await axios.post('/api/endereco/', {
            cep: cep,
            estado: uf,
            cidade: cidade,
            bairro: bairro,
            rua: logradouro,
            numero: null
          });
          responseEndereco = responseEndereco.data;

          let responsePessoa = await axios.post('/api/pessoa/', {
            nome_pessoa: nome,
            dt_nasc_pessoa: convertDate(nascimento),
            cpf_pessoa: tratamentoString(cpf),
            rg_pessoa: tratamentoString(rg),
            email_pessoa: email,
            senha_pessoa: senha,
            telefone_pessoa: tratamentoString(telefone),
            genero: genero,
            id_endereco: responseEndereco.id,
            adm: null
          });
          responsePessoa = responsePessoa.data;

          const responseAluno = await axios.post('/api/aluno', {
            id_pessoa: responsePessoa.id,
            destro_canhoto: mao_dominante,
            id_responsavel: null,
            dt_inicio: dataFormatadaMySQL
          });

          setResponsePessoa(responseAluno);
        } catch (error) {
          console.log("Erro ao criar aluno: ", error);
        }
      } else {
        try {
          let responseEndereco = await axios.post('/api/endereco/', {
            cep: cep,
            estado: uf,
            cidade: cidade,
            bairro: bairro,
            rua: logradouro,
            numero: null
          });
          responseEndereco = responseEndereco.data;

          let responsePessoaR = await axios.post('/api/pessoa/', {
            nome_pessoa: nomeResp,
            dt_nasc_pessoa: convertDate(nascimentoResp),
            cpf_pessoa: tratamentoString(cpfResp),
            rg_pessoa: tratamentoString(rgResp),
            email_pessoa: emailResp,
            senha_pessoa: senhaResp,
            telefone_pessoa: tratamentoString(telefoneResp),
            genero: generoResp,
            id_endereco: responseEndereco.id,
            adm: null
          });
          responsePessoaR = responsePessoaR.data;

          const responseResp = await axios.post('/api/responsavel_aluno', {
            id_pessoa: responsePessoaR.id,
          });

          let responsePessoa = await axios.post('/api/pessoa/', {
            nome_pessoa: nome,
            dt_nasc_pessoa: convertDate(nascimento),
            cpf_pessoa: tratamentoString(cpf),
            rg_pessoa: tratamentoString(rg),
            email_pessoa: email,
            senha_pessoa: senha,
            telefone_pessoa: tratamentoString(telefone),
            genero: genero,
            id_endereco: responseEndereco.id,
            adm: null
          });
          responsePessoa = responsePessoa.data;

          const responseAluno = await axios.post('/api/aluno', {
            id_pessoa: responsePessoa.id,
            destro_canhoto: mao_dominante,
            id_responsavel: responsePessoaR.id,
            dt_inicio: dataFormatadaMySQL
          });

          setResponsePessoa(responseAluno);
        } catch (error) {
          console.log("Erro ao criar aluno: ", error);
        }
      }
    }

  }

  if (responsePessoa) {
    alert("Sucesso!!!");
    window.location.reload();
  }

  return (
    <div className={Styles.container_formcadastro}>
      <form id="formcadastroaluno" className={Styles.form} autoComplete="off">
        <CSSTransition
          in={true}
          key={step}
          timeout={300}
          classNames="fade"
          unmountOnExit
        >
          {steps[step]}
        </CSSTransition>
      </form>
    </div>
  );
}

const Passo1 = ({ nextStep, email, setEmail, senha, setSenha, nome, setNome, rg, setRg, cpf, setCpf }) => (
  <div>
    <div className={Styles.textcenter}>
      <h1>Dados do Aluno</h1>
    </div>
    <div className={Styles.container_inputs}>
      <Email value={email} setValue={setEmail} />
      <Senha value={senha} setValue={setSenha} />
      <Nome value={nome} setValue={setNome} />
      <RG value={rg} setValue={setRg} />
      <CPF value={cpf} setValue={setCpf} />
      <br />
      <button type="button" onClick={nextStep} className={Styles.button}>Avançar</button>
    </div>
  </div>
);

const Passo2 = ({ nextStep, prevStep, telefone, setTelefone, nascimento, setNascimento, calcularIdade, genero, setGenero, mao_dominante, setMao_Dominante }) => (
  <div>
    <div className={Styles.textcenter}>
      <h1>Dados do Aluno</h1>
    </div>
    <div className={Styles.container_inputs}>
      <Telefone value={telefone} setValue={setTelefone} />
      <DtNasc value={nascimento} setValue={setNascimento} />
      <Genero value={genero} setValue={setGenero} />
      <DC value={mao_dominante} setValue={setMao_Dominante} />
      <button type="button" onClick={prevStep} className={Styles.button}>Voltar</button>
      <button type="button" onClick={() => {
        nextStep()
        calcularIdade(nascimento)
      }} className={Styles.button}>Avançar</button>
    </div>
  </div>
);

const Passo3 = ({ prevStep, cep, setCep, handleBuscarCep, uf, cidade, bairro, logradouro, isUnder18, cliquei, nextStep }) => (
  <div>
    <div className={Styles.textcenter}>
      <h1>Endereço</h1>
    </div>
    <div className={Styles.container_inputs}>
      <Cep onBuscarCep={handleBuscarCep} value={cep} setValue={setCep} />
      <UF u={uf} />
      <Cidade c={cidade} />
      <Bairro b={bairro} />
      <Rua r={logradouro} />
      <br />
      <button type="button" onClick={prevStep} className={Styles.button}>Voltar</button>
      <button
        type="button"
        onClick={() => {
          if (isUnder18()) {
            nextStep();
          } else {
            cliquei();
          }
        }}
        className={Styles.button}
      >
        {isUnder18() ? "Avançar" : "Concluir"}
      </button>
    </div>
  </div>
);

const Passo4 = ({ prevStep, nextStep, emailResp, setEmailResp, senhaResp, setSenhaResp, nomeResp, setNomeResp, rgResp, setRgResp, cpfResp, setCpfResp }) => (
  <div>
    <div className={Styles.textcenter}>
      <h1>Dados do Responsável</h1>
    </div>
    <div className={Styles.container_inputs}>
      <Email value={emailResp} setValue={setEmailResp} />
      <Senha value={senhaResp} setValue={setSenhaResp} />
      <Nome value={nomeResp} setValue={setNomeResp} />
      <RG value={rgResp} setValue={setRgResp} />
      <CPF value={cpfResp} setValue={setCpfResp} />
      <br />
      <button type="button" onClick={prevStep} className={Styles.button}>Voltar</button>
      <button type="button" onClick={nextStep} className={Styles.button}>Avançar</button>
    </div>
  </div>
);

const Passo5 = ({ prevStep, telefoneResp, setTelefoneResp, nascimentoResp, setNascimentoResp, generoResp, setGeneroResp, cliquei }) => (
  <div>
    <div className={Styles.textcenter}>
      <h1>Dados do Responsável</h1>
    </div>
    <div className={Styles.container_inputs}>
      <Telefone value={telefoneResp} setValue={setTelefoneResp} />
      <DtNasc value={nascimentoResp} setValue={setNascimentoResp} />
      <Genero value={generoResp} setValue={setGeneroResp} />
      <br />
      <button type="button" onClick={prevStep} className={Styles.button}>Voltar</button>
      <button type="button" onClick={cliquei} className={Styles.button}>Concluir</button>
    </div>
  </div>
);