// Import do React
import React from "react"
import {BrowserRouter as Routers, Routes, Route, Link} from "react-router-dom"

// Import das Telas
import Login from "./components/login/form_login/form"
import Cadastro from "./components/cadastro/form"
import CadastroResp from "./components/cadastro/cadastroResponsavel"

import CadastroProf from "./components/cadastrosDoAdm/cadastroProf/cadastro_prof"
import CadastroUnidade from "./components/cadastrosDoAdm/cadastroUnidade/cadastro_unidade"
import CadastroTurma from "./components/cadastrosDoAdm/cadastroTurma/cadastro_turma"

import Home from "./components/home/home"

import Calendario from "./components/calendario/calendario"
import Alunos from "./components/calendario/aluno_calendario/alunos_calendario"

import Adm from "./components/adm/adm"

import AdmAluno from "./components/adm/adm_aluno/adm_aluno"
import AdmProf from "./components/adm/adm_professor/adm_professor"
import AdmUni from "./components/adm/adm_unidade/adm_unidade"
import AdmTurma from "./components/adm/adm_turma/adm_turma"

export default function App(){

  return(
    <Routers>
      {/* Links */}
        <Link to="/"></Link>
        <Link to="/cadastro"></Link>
        <Link to="/cadastro/responsavel"></Link>

        <Link to="/cadastro/professor"></Link>
        <Link to="/cadastro/unidade"></Link>
        <Link to="/cadastro/unidade/responsavel"></Link>
        <Link to="/cadastro/turma"></Link>

        <Link to="/home"></Link>

        <Link to="/aulas"></Link>
        <Link to="aulas/alunos_aulas"></Link>

        <Link to="/adm"></Link>
  
        <Link to="adm/adm_aluno"></Link>
        <Link to="adm/editar_aluno"></Link>

        <Link to="adm/adm_prof"></Link>
        <Link to="adm/editar_prof"></Link>

        <Link to="adm/adm_unidade"></Link>
        <Link to="adm/editar_unidade"></Link>
        <Link to="adm/editar_res-unidade"></Link>

        <Link to="adm/adm_turmas"></Link>
        <Link to="adm/editar_turma"></Link>
        
      {/* Rotas */}
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/cadastro" element={<Cadastro />}></Route>
        <Route path="/cadastro/responsavel" element={<CadastroResp />}></Route>
        
        <Route path="/cadastro/professor" element={<CadastroProf texto={"Cadastro de Professores"} botao={"Cadastrar"}/>}></Route>
        <Route path="/cadastro/unidade" element={<CadastroUnidade texto={"Cadastro de Unidade"} botao={"Cadastrar"}  url={"/cadastro/unidade/responsavel"}/>}></Route>
        <Route path="/cadastro/turma" element={<CadastroTurma texto={"Cadastro de Turmas"} botao={"Cadastrar"}/>}></Route>

        <Route path="/home" element={<Home/>}></Route>

        <Route path="/aulas" element={<Calendario />}></Route>
        <Route path="aulas/alunos_aulas/:idturma" element={<Alunos />}></Route>

        <Route path="/adm" element={<Adm />}></Route>
        
        <Route path="adm/adm_aluno" element={<AdmAluno />}></Route>
        <Route path="adm/editar_aluno/:id_aluno" element={<Cadastro texto={"Editar"}  botao={"Editar"}/>}></Route>

        <Route path="adm/adm_prof" element={<AdmProf />}></Route>
        <Route path="adm/editar_prof/:id_professor" element={<CadastroProf texto={"Editar Professor"} botao={"Editar"}/>}></Route>

        <Route path="adm/adm_unidade" element={<AdmUni />}></Route>
        <Route path="adm/editar_unidade/:id_unidade" element={<CadastroUnidade texto={"Editar Unidade"} botao={"Editar"} url={"/adm/editar_unidade/responsavel"}/>}></Route>

        <Route path="adm/adm_turmas" element={<AdmTurma />}></Route>
        <Route path="adm/editar_turma/:id_turma" element={<CadastroTurma texto={"Editar Turma"} botao={"Editar"}/>}></Route>
      </Routes>
    </Routers>
  )
}